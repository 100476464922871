import type {
  ActionFunction,
  LoaderFunction,
  MetaFunction,
} from '@remix-run/node'
import { json, redirect } from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import * as React from 'react'
import { CheckCircleIcon } from '@heroicons/react/solid'

import { createUserSession, getUserId } from '~/session.server'
import { verifyLogin, getUserByEmail } from '~/models/user.server'
import { safeRedirect, validateEmail } from '~/utils'

import * as jwt from '~/jwt.server'
import { sendEmail } from '~/postmark.server'

import logo from '~/images/logo.svg'
import { addEvent } from '~/models/event.server'

export const loader: LoaderFunction = async ({ request }) => {
  const userId = await getUserId(request)
  if (userId) return redirect('/app')
  return json({})
}

interface ActionData {
  errors?: {
    email?: string
    password?: string
  }
  ok?: boolean
}

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const email = formData.get('email') as string
  const redirectTo = safeRedirect(formData.get('redirectTo'), '/app')

  const user = await getUserByEmail(email)

  if (!user) {
    await addEvent({
      name: 'login.attempt',
      payload: { email, success: false },
    })

    return json<ActionData>(
      { errors: { email: 'Invalid email' } },
      { status: 400 }
    )
  }

  const payload = {
    redirectTo,
  }

  const options = {
    // algorithm: 'none',
    expiresIn: '1h',
    audience: email,
    issuer: 'growhire.com',
  }

  const token = jwt.sign(payload, process.env.MAGIC_LINK_SECRET!, options)

  // @FIXME: @REFACTOR this is not necessary
  let loginLink = process.env.DOMAIN
    ? process.env.DOMAIN
    : 'https://localhost:3000'
  loginLink += `/login/${token}`

  if (process.env.NODE_ENV === 'development') {
    console.log('------------- Login link ----------------')
    console.log(loginLink)
  }

  await addEvent({ name: 'login.attempt', payload: { email, success: true } })

  await sendEmail({
    to: email,
    template: 'magic-link',
    variables: {
      product_name: 'Growhire',
      product_url: 'http://growhire.com',
      action_url: loginLink,
      name: user.firstName,
    },
  })

  return json({ ok: true })
  // return loginLink
}

export const unused_____________action: ActionFunction = async ({
  request,
}) => {
  const formData = await request.formData()
  const email = formData.get('email')
  const password = formData.get('password')
  const redirectTo = safeRedirect(formData.get('redirectTo'), '/app')
  const remember = formData.get('remember')

  if (!validateEmail(email)) {
    return json<ActionData>(
      { errors: { email: 'Email is invalid' } },
      { status: 400 }
    )
  }

  if (typeof password !== 'string' || password.length === 0) {
    return json<ActionData>(
      { errors: { password: 'Password is required' } },
      { status: 400 }
    )
  }

  if (password.length < 8) {
    return json<ActionData>(
      { errors: { password: 'Password is too short' } },
      { status: 400 }
    )
  }

  const user = await verifyLogin(email as string, password)

  if (!user) {
    return json<ActionData>(
      { errors: { email: 'Invalid email or password' } },
      { status: 400 }
    )
  }

  return createUserSession({
    request,
    userId: user.id,
    remember: remember === 'on' ? true : false,
    redirectTo,
  })
}

export const meta: MetaFunction = () => {
  return {
    title: 'Login',
  }
}

export default function LoginPage() {
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo') || '/app'
  const actionData = useActionData() as ActionData
  const emailRef = React.useRef<HTMLInputElement>(null)
  // const passwordRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus()
    } else if (actionData?.errors?.password) {
      // passwordRef.current?.focus();
    }
  }, [actionData])

  const isSuccess = actionData?.ok

  return (
    <>
      <div className="flex min-h-full flex-col justify-center bg-gray-100 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={logo} alt="Growhire" />
          <h2 className="mt-12 text-center text-3xl font-extrabold text-gray-900">
            Log in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <Link
              to="/join"
              className="font-medium text-teal-600 hover:text-teal-500"
            >
              create your free account
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            {isSuccess && (
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-md font-medium text-green-800">
                    Confirm your email
                  </h3>
                  <div className="text-md mt-2 text-gray-700">
                    <p>We sent you an email with your one-time login link.</p>
                  </div>
                </div>
              </div>
            )}
            {!isSuccess && (
              <form className="space-y-6" action="#" method="POST">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      autoFocus={true}
                      required
                      ref={emailRef}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                    />
                    {actionData?.errors?.email && (
                      <div
                        className="pt-1 text-sm text-red-700"
                        id="password-error"
                      >
                        {actionData.errors.email}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function Success() {
  return <div className="mb-8 rounded-md bg-white p-4"></div>
}
